*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.pointer{
    cursor: pointer;
}

a{
    text-decoration: none;
}