// @import "createAccount";
@import "_mixins";
@import "_variables";
.background{
    background-color: $mo-light;
}
.reset-autoresponse-container{ 
    background-color: #EAEDC6;
    width:70%;
    margin: 30px auto;
    padding: 30px; 
    .content{ 
        padding: 60px;
        background-color: $mo-light;
        p{
            width: 70%;
        }

        .button-area{
            background-color: #EAEDC6;
            width: 100%; 
            height: 90px;
            display: flex;
            align-items: center;
            justify-content: center;

            button{
                @include buttonStyle($mo-light, $mo-info, none);
                width: 30%;
                border-radius: 5px;
            }
            margin: 30px 0px;
        }
        .contact{
            line-height: 9px;
        }
        .socials{
            display: flex;
            justify-content: center;
            align-items: center;  
            a{
               width: 80px;
               img{
                width: 100%;
               }
            }
        }
    }
    .welcome{
        display: flex;
        align-items: center;
        gap: 10px; 

        h2{
            font-weight: 700;
            font-size: 25px;
        }

        img{
            width: 30px;
        }

    }

}