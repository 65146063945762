@import "_variables";
@import "_mixins";

.btn-active {
    @include buttonStyle($mo-info, $mo-light, none);
}

.btn-inactive {
    @include buttonStyle($mo-grey, black, none);
}

.modalContent {


    .finish-setup-form-container{ 
        height: auto;
        overflow-y: auto;
    }

    width: 70%;
    margin: auto;
    // padding-bottom: 80px;
//     height: 70vh;
// overflow: hidden;
    .image {
        width: 20%;
        margin: 20px auto;

        img {
            width: 100%;
        }
    }

    h2 {
        font-size: 20px;
        font-weight: 700;
        margin: 10px 0px;
    }

    .mantra {
        font-size: 13px;
        text-align: center;

    }

    p {
        font-weight: 200;

    }

    .buttons {
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin: 60px 0px;

        .disabled-button {
            @include buttonStyle(transparent, black, none);
            text-decoration: underline;
        }
    }

    .input-area {
        margin: 20px 0px;
        font-size: 14px;

        label {
            position: relative;
            top: 10px;
            font-weight: 600;
            left: 15px;
            background-color: $mo-light;
            padding: 0px 5px
        }

        .tag {
            margin: 20px 0px;

            img {
                width: 100%;
                object-fit: contain;
            }
        }



    }


    input,
    select, textarea {
        @include inputStyle(1px solid $mo-grey)
    }

    .accountInput {
        border: 1px solid $mo-info;
        background-color: #EFF3BE;
        color: black;
    }

    .username {
        margin: auto;
        width: 50%;

        input {
            border: none;
            width: 100%;
        }

        label {
            position: relative;
            top: 30px;
            left: -12px;
            font-weight: 700;
            background-color: transparent;

        }
        .search-username{
            width: 15px;
            cursor: pointer;
            img{
                width: 100%;
            }
        }
    }


    .account-area{
        p{
            // line-height: 5px;
            padding: 0;
            margin:0
        } 
        b{
            font-size: 14px
        }
        @include flex-between-center;  
        margin: 15px 0px
    }

    .note{
        margin-top: 30px;
        background-color: rgba($color: #FFAB01, $alpha: 0.1);
        padding: 10px;
        border-radius: 10px;

        h5{
            font-size: 13px;
            font-weight: 600
        }

        p, ol li{
            font-size: 12px
        }
    }

    .toast{
        position: absolute;
        @include flex-center-center; 
        width: 60%; 
        right: 0;
    }

}
















.choose-contact {
    .head {
        @include flex-between-center;
        font-size: 20px;
    }

    p {
        padding: 0;
        margin: 0;
    }
 

    .contact {
        @include flex-between-center; 
        margin: 10px 0px;
        .details {
            @include flex-between-center;
            width: 75%;
            gap: 7px; 

            .image {
                width: 30px;
                height: 30px;
                overflow: hidden;

                img {
                    width: 100%;
                    height: 100%;
                    border-radius: 100%;
                    object-fit: cover;
                    object-position: top;
                }
            }

            .names{
                width: 80%;
                // font-size: 12px;
            }

            .first {
                font-weight: 700;

            }
        }
    }

    .box{
        position: absolute;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        // justify-content: space-between;
        margin: 20px 0px;
      
        input{
        //   padding: 0;
          font-size: 10px;
          width: 90% ;
        } 
        width: 100% ;
       }
}

.button-section {
    margin: 30px 0px;
}

.profile {
    .image {
        width: 30px;
        height: 30px;
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            border-radius: 100%;
            object-fit: cover;
            object-position: top;
        }
    }
}

.select-amount {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    width: 90%;
    justify-content: center;
    margin: 20px 0px;

    button{
        border: none;
        padding: 5px 10px;
        border-radius: 10px;
        font-size: 12px;
        font-weight: 600;
        background-color: #EDF2F6;
    }

    .amount{
        border: none;
        padding: 5px 10px;
        border-radius: 10px;
        font-size: 12px;
        font-weight: 600;
        background-color: #EDF2F6;
        width: 40%;
        border: 2px solid $mo-info;
    }
}

.input-area {
    .checkbox {
        margin: 0px 0px 0px -10px;

        input {
            width: 10%;
        }
    }
}

.wallet-section {
    font-size: 12px;
    @include flex-center-center;
    gap: 2px;

    .wallet {
        font-weight: 600;
    }

    .balance {
        color: $mo-info;
    }
}

.modal-header {
    border: none;
    @include flex-end-center;
}



@media(max-width:"400px"){
    .modalContent{
        width: 100% ; 
        // display: flex;
        // flex-direction: column;
        // align-items: center;

       
    }
}