@import"_variables";
@import "_mixins";
@import "createAccount";
@import "updatePassword";


.upload-image-container {
    @extend .login-form-container;

    .file-input-area {
        background-color: #FCFDEB;
        border: 2px dashed;
        width: 250px;
        height: 250px;

        .label {
            position: relative;
            top: 0;
            left: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            // line-height: 5px;
            cursor: pointer;
        }
img{
    width: 100%;
    height: 100%;
    object-fit: contain; 
}
    }


    input {
        opacity: 0;

    }

    .button {
        background-color: $mo-grey !important;

       
    }
}


.upload-id-field{
    @extend .signup-form-container; 
    width: 100%;
    margin: 10px 0px;
    
     
    .file-input-area{ 

        border: 0px;  
        height: 120px;
        background-color: #FDFBF7; 
        
        .label{
            padding: 30px;
            background-color: #FDFBF7; 
            width: 100%; 
        }
        
        input{ 
            position: relative;
            top: -60px;

        }

    }
}

.small-p{
    font-size: 11px;
    font-weight: 600 !important;
}