@import "_variables";
@import "_mixins";

.mo-info {
  color: $mo-info;
}
.mo-blue {
  color: $mo-blue;
}
.mo-success {
  color: $mo-success;
}
.mo-danger {
  color: $mo-danger;
}

.mo-active-btn {
  @include buttonStyle($mo-info, $mo-light, none);
}
.mo-active-btn-25 {
  @include buttonStyle($mo-info, $mo-light, none);
  width: 50%;
}

.mo-success-btn {
  @include buttonStyle($mo-success, $mo-light, none);
  width: 50%;
}

.mo-inactive-btn {
  @include buttonStyle($mo-grey, black, none);
}

.mo-skip-btn {
  @include buttonStyle(transparent, $mo-blue, none);
}

.active-amount {
  border: 2px solid $mo-info !important;
}

// .other-body{
//     position: relative;
//     left: 20%;
//     width: 80%;
//     margin: 20px 10px;
//     padding: 20px;
// }

body {
  // background-color: #F1F1F1;
  // background-color: rgba(209, 209, 209, 0.3);
}
.signup-form-container,
.login-form-container {
  // background-color:white
}

.error-msg {
  color: $mo-danger;
  background-color: #f8f4eb;
  border: 1px dashed $mo-danger;
  font-size: 13px;
  padding: 10px;
  border-radius: 7px;
}

.failed {
  background-color: rgba(255, 0, 0, 0.08) !important;
}

.pending {
  color: #969ba0;
}

.skeleton {
  width: 100%;
  height: 100%;
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 20%, 80%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

//TERMS MODAL
pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  font-size: 11px;
  font-family: Museo Sans Rounded;
  font-weight: 200;
}

.mongoro-terms-headings {
  b {
    font-size: 12px;
    font-weight: 800;
  }

  .terms-applied {
    font-size: 12px;
    font-weight: 500;
  }

  .text {
    font-size: 12px;
  }
}

.mongoro-terms-content {
  .headings {
    li {
      font-weight: 700;
      font-size: 11px;
    }
    ol {
      list-style-position: outside;
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #0d95891a;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: rgba(128, 128, 128, 0.3);
  border-radius: 10px;
}
// ::-webkit-scrollbar-thumb:hover {
//   background: darken(orange, 1%);
// }

.modal {
  ::-webkit-scrollbar {
    width: 5px;
    background-color: #0d95891a;
  }
  ::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: $mo-info;
    border-radius: 10px;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: darken(orange, 1%);
  }
}

.neutral-mantra {
  background-color: #f8f4eb;
  border: 1px dashed #a2ad1f;
  font-size: 13px;
  padding: 10px;
  border-radius: 7px;
  margin: 10px 0px;
  text-align: center;
  b {
    font-weight: 700;
  }
}

.grey-area {
  background-color: rgba(128, 128, 128, 0.1);
  .text {
    color: rgba(0, 0, 0, 0.5);
  }
  .coming-soon {
    font-size: 11px;
  }
}

.general-toast { 
  position: fixed;
  right: 5%;
  top: 15%;
  // display: flex;
}

.table-truncate{ 
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  max-width: 120px;
}



.oops{
  width: 100px;
  img{
    width: 100%;
  }
}


.coming-soon-container{
  width: 35px;
  img{
    width: 100%;
  }
}


.not-allowed{
  cursor:not-allowed;
}


.choose-beneficiary{
  margin-bottom: 10px;
  font-size: 12px;
  color: $mo-info;
  cursor: pointer;
  user-select: none;
  width: 40%;
}

.enter{ 
  background-color: $mo-info; 
  @include flex-center-center;
  color: white;
  font-size: 20px;
  width: 50px; 
  user-select: none;
  cursor: pointer;
}