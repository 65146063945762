@import "_mixins";
@import "_variables";

.active-section {
  color: $mo-info;
  padding: 5px 0px;
  border-right: 4px solid $mo-info;
  svg {
    path {
      stroke: $mo-info;
    }
  }
}

.sidebar-container {
  background-color: $mo-light;
  width: 18%; 
  padding: 10px;
  padding-left: 30px;
  height: 90vh;
  overflow-y: scroll;
  z-index: 10;
  position: fixed;

  .header {
    color: #c7c7c7;
    h5 {
      font-weight: 600;
    }
  }

  .items-section {
    // padding: 0px 15px;

    .item {
      @include flex-none-center;
      gap: 15px;
      margin: 35px 0px;
      font-size: 12px;
      user-select: none;
      cursor: pointer;

      // .item-icon{
      //     width: 20%;
      // }
      // .item-title{
      //     width: 70%;
      // }
    }

    //    .icon{
    //         font-size: 16px;
    //     }
    .icon {
      width: 16px;
      svg {
        width: 100%;
      }
    }
    .activity {
      @include flex-between-center;
      width: 80%;
    }
  }
}

.switch {
  display: none;
}

@media (max-width: 700px) {
  .verification-status-container {
    margin-top: 10px;
  }
 
  .dashboard-container {
    .sidebar, .hide-sideba {
      width: 80% !important;
      z-index: 1 !important;
    }
  }

  .sidebar-container {
    width:50%;
    .switch {
      display: block;

      .open {
        position: absolute;
        left: 0;
      }

      .close {
        position: absolute;
        right: 0;
      }
    }
  }

  .hide-sidebar {
    background-color: transparent;
    padding: 0;
    width: 0 !important;
    overflow: 0 !important;
    // width: 80% !important;
    // z-index: 1 !important;

// position: fixed;
    // background-color: red;
    // border: 5px solid blue;
    // z-index: -1;
  }

  // .dashboard-container{
  //     .sidebar {
  //         width: 0 !important;
  //         z-index: 0 !important;
  //       }
  // }
}
