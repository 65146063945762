@import "_variables";
@import "_mixins";
p {
  padding: 0;
  margin: 0;
}

.ticket-summary-container {
  margin: 30px 0px;
  user-select: none;
  .cards-section {
    @include flex-none-center;
    flex-wrap: wrap;
    gap: 10px;
    margin: 20px 0px;
    .support-card {
      padding: 10px;
      flex-basis: 220px; 
      border-radius: 10px;
      box-shadow: 0px 0px 10px 0px #d7d6d6;
      cursor: pointer;

      .text {
        width: 70%;
        p {
          padding: 0;
          margin: 0;
        }
      }
      .icon {
        width: 50px;
        img {
          width: 100%;
        }
      }
      @include flex-between-center;
      background-color: $mo-light;
      &:hover {
        background-color: $mo-info;
        color: $mo-light;
      }
    }

    .inactive-ticket {
      background-color: white;

      .text {
        p,
        b {
          color: black;
        }
      }
    }
  }

  .create-tictket-button {
    button {
      @include buttonStyle($mo-info, $mo-light, none);
      width: 150px;
    }
  }
}

.tickets-list-container {
  .table-section {
    padding: 50px;
    height: auto !important;
    td {
      padding: 15px !important;
    }
  }
  center {
    padding: 20px;
    width: 70%;
    margin: auto;
  }

  .buttons { 
    margin: 10px auto;
    gap: 10px;
    .mo-skip-btn {
      color: $mo-info;
    }
  }
}

//TICKET DETAILS PAGE
.ticket-details-container {
  font-size: 12px;

  background-color: white;
  border-radius: 10px;
  padding: 20px;

  .faint {
    font-weight: 500;
    color: #c7c7c7;
  }
  b {
    font-weight: 500;
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;

    .id {
      p {
        font-weight: 700;
        font-size: 16px;
      }
    }
    // font-size: 14px;

    .contact {
      display: flex;
      gap: 10px;
      .icon {
        width: 30px;
        img {
          width: 100%;
        }
      }
    }

    .ticket-status {
      display: flex;
      align-items: center;
      gap: 20px;

      .closed,
      .archived {
        display: flex;
        gap: 10px;
      }
    }

    .close {
      width: 20px;
      img {
        width: 100%;
      }
    }
  }

  .ticket-details {
    display: flex;
    justify-content: space-between;
    // flex-wrap: wrap;
    // gap: 10px;
    margin: 20px 0px;
    padding: 20px 0px;

    border-top: 0.4px solid grey;
    border-bottom: 0.4px solid grey;

    .author {
      width: 21%;
      display: flex;
      padding: 5px;
      gap: 10px;
      justify-content: space-between;
      .avatar {
        width: 50px;
        height: 50px;
        border-radius: 100%;

        img {
          width: 100%;
          height: 100%;
          border-radius: 100%;
          object-fit: cover;
          object-position: top;
        }
      }

      .name {
        width: 70%;
        .first-name {
          font-weight: 500;
        }
        p {
          font-size: 11px;
          word-wrap: break-word;
        }
      }
    }

    .about-amount-section {
      display: flex;
      justify-content: space-between;
      width: 78%;

      .about-ticket {
        display: flex;
        flex-wrap: wrap;
        width: 70%;
        gap: 10px;

        .item {
          flex-basis: 120px;
          margin: auto;
          // font-size: 14px;
        }
      }

      .amount-section {
        display: flex;
        align-items: center;
        //   box-shadow: 0px 0px 5px 0px #2e2e2e;
        border: 0.4px solid #32d16d;
        padding: 5px;
        border-radius: 7px;
        gap: 5px;
        height: 50px;

        .icon {
          width: 20%;
          img {
            width: 100%;
          }
        }
        font-weight: 300;

        .value {
          font-weight: 500;
          color: #2e2e2e;
        }
      }
    }
  }
}

.close-ticket-container {
  box-shadow: 0px 0px 3px 0px grey;
  padding: 3px;
  border-radius: 7px;

  font-weight: 500;
  // position: relative;
  // right: auto;
  width: 100px;
  // left: -10px;

  background-color: white;
  overflow: hidden;
  .ticket-prompt {
    @include flex-evenly-center;

    gap: 5px;
    font-size: 10px;
    padding: 5px;
    cursor: pointer;
    .icon {
      width: 20%;
      img {
        width: 100%;
      }
    }

    p {
      transition: all 0.1s linear;
    }
    &:hover {
      gap: 10px;
      p {
        transform: scale(1.1);
      }
    }
  }
}

.ticket-body {
  display: flex;
  justify-content: space-between;

  .description {
    width: 60%;

    .body {
      margin: 20px 0px;
    }
  }
  .account {
    background-image: url("../icons/cardstack.png");
    background-repeat: no-repeat;
    height: 170px;
    border-radius: 10px;
    margin: auto;
    padding: 20px;
    color: $mo-light;
    background-size: contain;
    .accountNumber {
      font-size: 11px;
    }

    h4 {
      margin: 0;
    }

    .last {
      display: flex;
      align-items: flex-end;
      height: 25%;
      font-size: 15px;
    }
  }
  .account {
    width: 30%;
  }
}














@media (max-width: 505px) {
  .ticket-summary-container {
    margin: 0;

    .create-tictket-button {
      width: 95%;
    }

    .cards-section { 
      .support-card {
        flex-basis: 150px;
        font-size: 12px;
        .text{
          width: 80%;
        }
        // flex-grow: 0.5;
        // margin: 10px auto;
      }
    }
  }


  .tickets-list-container{
    center {
      padding: 20px;
      width: 100%;
      margin: auto;
    }
  
  }
  .ticket-details-container {
    .about-amount-section {
      display: flex;

      .amount-section,
      .about-ticket {
        width: 50% !important;
      }
    }
    .ticket-details {
      flex-direction: column;
      .author {
        width: 100%;
      }
    }

    .account {
      display: none;
      width: 60%;
      font-size: 11px;
      height: 170px;
      h4 {
        font-size: 13px;
      }
      .last {
        // height: 15%;
        font-size: 10px;
      }
    }
  }
 
}
