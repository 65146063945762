@import "_mixins";
@import "_variables";
@import "dashboard";
@import "createAccount";

.settings-container {
  @extend .activities-container; 
  .list-body {
    padding: 30px;
  }

  .user-profile {
    @include flex-none-center;
    gap: 30px;
    margin: 20px 0px;

    .image {
      background-image: url(../icons/img-placeholder.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100px;
      height: 100px;
      border-radius: 100%;
img{
  width: 100%;
  height: 100%;
  border-radius: 100%;
  object-fit: cover;
  object-position: top;
}
      input {
        display: none;
        z-index: 3;
      }
      label {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        display: flex;
        align-items: flex-end;
        
        // position: relative;
        // top: 10px;
      }
    }
.shown-img{
  @extend .image;
  background-image: none;
}
    .name {
      h5 {
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  .item {
    user-select: none;
    @include flex-none-center;
    margin: 20px 0px;
    .parameter {
      width: 50%;
      b {
        font-size: 15px;
      }
    }

    .value {
      width: 40%;
      p {
        font-size: 13px;
        font-weight: 500;
      }
    }
  }

  .cards {
    @include flex-none-center;
    flex-wrap: wrap;
    gap: 40px;

    .add-bank {
      text-align: center;
      border: 1.5px solid $mo-info;
      @include flex-center-center;
      flex-direction: column;
      font-weight: 600;

      .icon {
        font-size: 20px;
      }
    }
    .added-bank,
    .add-bank {
      height: 150px;
      width: 35%;
      // flex-basis: 35%;
      border-radius: 10px;
    }

    .added-bank {
      background-color: $mo-info;
      padding: 10px;
      color: $mo-light;
      display: flex;
      align-items: flex-end;
      // transition: all 0.3s 0s;

      .holder {
        height: 80%;
        transition: all 0.3s linear 0s;
      }
      h5 {
        font-weight: 700;
        font-size: 15px;
      }

      .bank-name {
        font-weight: 100;
        font-weight: 1px;
      }

      &:hover {
        padding: 0;
        .holder {
          display: none;
        }
        .delete-bank {
          transition: all 0.3s 0s;

          display: flex;
          padding: 10px;
          width: 100%;
          flex-basis: 100%;
      height: 100%;
      opacity: 100
        }
      }
    }

    .delete-bank {
      @extend .added-bank;
      width: 0%;
      height: 0%;
      background-color: #fdfbf7;
      @include flex-none-center;
      // display: none;
      opacity: 0;
      transition: all 0.3s 0s;

    } 
     
  }

  .notification-value {
    @include flex-between-center;
    gap: 15px; 
  }

  .button button {
    width: 50%;
  }
  .notify,
  .via {
    @include flex-none-center;
    gap: 5px; 
  }


.add-bank-form-section{
  @include flex-none-center;
  .text{
    width: 50%;
  }
  .form{
    width: 50%;
  }
}









  .input-area {
    margin: 10px 0px;
    font-size: 14px;

    label {
      position: relative;
      top: 10px;
      font-weight: 600;
      left: 15px;
      background-color: $mo-light;
      padding: 0px 10px 0px 5px;
    }
  }

  input, select {
    @include inputStyle(1px solid $mo-grey);  
  }
  select{

    box-sizing: content-box;
    option{
      text-indent: 30px;
      appearance: none;
      padding-right: 200px;
    }
  }
}
.eye{  
  position: relative;
  // width: 30%;
  top: -30px; 
  // right: -10;
  left: 90%; 
  display: flex;
  justify-content: flex-end;



  img{
      position: relative;
      // width: 40px
  }
  
}


.hide-on-modal{
  // @extend .verify-account-form-container;
  // @extend .create-account-container;
  // @extend .login-form-container;;
  // @extend .upload-image-container;
 
  // width: 100%;
  .hide-on-modal{
    display: none !important;  
  }  

  .upload-image-container, .verify-account-form-container{
    width: 100%;

    .content{
      width: 100%;
    }
  } 

  .form-area{
    width: 100% !important; 
  }

}
 


@media(max-width:"400px"){
  .settings-container{
    .list-body{
      width: 100%;
      padding: 5px;
    }

    .item{
      font-size: 12px;  
      width: 100%;
      align-items: center; 
      gap: 20px;
      padding: 10px;
      .parameter{
        width: 40%; 
        b{
          font-size: 12px; 
        }
      }

.value{
      width: 60%;  

      display: flex;
      align-items: center;
      p{
        display: flex;
        align-items: center;
        padding: 0px;
        margin: 0;
      }
      
    }

    }

    
  } 

  .login-activity-container{
   .item{
    .parameter{
      width: 95%; 
    }
    .value{ 
      width: 5%;
    }
   }
  }

 .image-section{
  label{
    font-size: 12px;
  }
 }

  .input-area{ 
    width: 100% !important; 
  }

  
}