@import "_variables";
@import "_mixins";

.create-account-container {
  font-family: $primary-font;
  display: flex; 
  gap: 10px; 

  .background-area {
    background-image: url(../images/frame.svg);
    width: 45%;
    height: 100vh;
    overflow: hidden;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: left; 
    margin: 0; 
    .content {
      width: 75%;
    //   height: 90%; 
      margin: auto; 
      height: 100%;
      padding: 20px 0px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 50px;
    }

    header {
      font-weight: 700;
      font-size: 30px;
      color: $mo-light;
      width: 100%;
      height: 15%;
    }

    .overlay {
      height: 60%;
      img { 
        height: 100%; 
      }
    }

    .logo {
      width: 30%;
      img {
        width: 100%;
      }
      height: 20%;
    }
  }
}

.error {
  color: $mo-danger;
  background-color: #f8f4eb;
  border: 1px dashed $mo-danger;
  font-size: 13px;
  padding: 10px;
  border-radius: 7px;
  margin: 10px 0px;
}

.success {
  color: $mo-success;
  background-color: rgba($color: $mo-success, $alpha: 0.2);
  border: 1px dashed $mo-success;
  font-size: 13px;
  padding: 10px;
  border-radius: 7px;
}

//SIGNUP FORM STYLES
.signup-form-container {
  width: 55%;
  box-sizing: border-box;
  height: 100vh; 
  overflow-y: scroll;
  // align-items: center;
  .form-area {
    width: 100%;
    padding: 20px 0px;
    display: flex;

    .login-redirect-link { 
      position: absolute;
      padding: 10px 20px;
      right: 10%;
      width: 60%;
      display: flex;
      justify-content: flex-end;
    }

    .mantra {
      margin: 10px 0px;
    }

    .content {
      width: 50%;
      margin: 0px auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 100%;

      h2 {
        font-size: 20px;
        font-weight: 700;
      }
    }

    .input-area {
      margin: 3px 0px;
      font-size: 14px;

      label {
        position: relative;
        top: 10px;
        font-weight: 600;
        left: 15px;
        background-color: $mo-light;
        padding: 0px 5px;
      }

      .eye {
        position: relative;
        // width: 30%;
        top: -30px;
        // right: -10;
        left: 90%;
        display: flex;
        justify-content: flex-end;

        img {
          position: relative;
          // width: 40px
        }
      }
    }

    input {
      @include inputStyle(1px solid $mo-grey);
    }

    button {
      @include buttonStyle($mo-info, $mo-light, none);

      &:hover {
        background-color: darken($color: $mo-info, $amount: 10%);
      }
    }

    .phone {
      display: flex;

      select {
        border: 1px solid $mo-grey;
        border-right: none;
        outline: none;
      }

      input {
        border-left: none;
      }
    }

    .terms-agreement-area {
      display: flex;
      align-items: flex-start;
      // justify-content: space-between;
      gap: 10px;
      margin: 10px 0px;
      line-height: 0.5cm;

      .checkbox {
        width: 10%;
      }

      p {
        width: 90%;
        font-size: 11px;

        .link {
          color: $mo-blue;
          text-decoration: underline;
        }
      }
    }
  }
}


.upload-image-container{
  height:auto;
  overflow: hidden;
}
//LOGIN FORM
.login-form-container {
  @extend .signup-form-container;

  .forgot-password {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    margin: 15px 0px;

    .checkbox-area {
      display: flex;
      gap: 10px;
    }
  }

  .signup-redirect-link {
    @extend .login-redirect-link;
  }
}

//FORGOT PASSWORD

.forgot-password-form-container {
  @extend .login-form-container;

  .content {
    .input-area {
      margin: 40px 0px;
    }

    button {
      margin: 15px 0px;
    }

    .forgot-password p {
      width: 100%;
      text-align: center;
    }
  }
}

//FINISH ACCOUNT SETUP
.finish-setup-form-container {
    @extend .signup-form-container;
//   @extend .login-form-container;
//   overflow-y: scroll;

.content{
    display: block !important;
}

  select {
    @include inputStyle(1px solid $mo-grey);
    border-right: 1px solid $mo-grey !important;
  }

  .buttons {
    .next-btn {
      @include buttonStyle($mo-grey, $mo-light, none);
      margin: 10px 0px;

      &:hover {
        background-color: darken($mo-grey, 5%);
      }
    }

    .skip-btn {
      @include buttonStyle($mo-light, $mo-blue, none);

      &:hover {
        background-color: darken($mo-light, 5%);
      }
    }
  }
}

//ACCOUNT CREATION SUCCESS

.account-success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60%;

  .image {
    width: 40%;

    img {
      width: 100%;
    }
  }

  h2 {
    font-weight: 600;
  }

  p {
    width: 55%;
    font-size: 12px;
    font-weight: 100;
    margin: 20px 0px;
    line-height: 0.7cm;
  }
}

//VERIFY ACCOUNT
.verify-account-form-container {
  @extend .login-form-container;
overflow: hidden;
height: 60vh;
  .otp-box {
    display: flex;
    align-items: center;
    // justify-content: center;
    gap: 10px;
    margin: 10px 0px;

    input {
      width: 20%;
      padding: 5px;
      font-size: 12px;
      text-align: center;

      &:focus {
        border: $mo-info 1px solid;
      }
    }
  }

  .code-here {
    font-size: 13px;
    text-align: center;
    width: 65%;
  }

  button {
    margin: 20px 0px;
  }

  .email-sms-otp {
    @include flex;
  }

  .separator {
    @include flex-none-center;
    height: 50%;
    width: 40%;
    font-weight: 500;
    font-size: 30px;
  }
}

.complete_setup_modal_container {
  .finish-setup-form-container {
    width: 100%;
    margin: 0;

    .form-area {
      padding: 0;
    }
    .content {
      width: 100%;
      margin: 0;
      h2 {
        // display: none;
      }
    }
  }
}

@media (max-width: 700px) {
  .background-area {
    display: none;
  }
  .signup-form-container {
    width: 100%; 
}

  .content {
    width: 80% !important;
    margin: auto;
  }

  .login-redirect-link,
  .signup-redirect-link {
    width: 100% !important;
  }
}
