@import "_variables";
@import "_mixins";

.dashboard-container {
  // background-color: $mo-bg-grey;
  background-color: rgba(209, 209, 209, 0.3);

  .body-section {
    display: flex;
    // gap: 20px;
    // background-color: green;
    width: 99%; 
  }

  .sidebar {
    width: 20%;
    top: 70px;
    // background-color: pink;
    position: fixed;
    // overflow-y: scroll !important;
    // height: 100vh !important;
  }

  .dashboard-body {
    position: relative;
    left: 20%;
    width: 80%;
    margin: 20px 10px;
    padding: 20px;
  }
}

.navigation-section {
  // background-color: blue;
  height: 70px;
}

//VERIFICATION STATUS

.finish-prompt {
  background-color: $mo-light;
  background-image: url("../icons//mail_bg.png");
  background-repeat: no-repeat;
  background-position: right;
  background-size: contain;
  padding: 10px;
  border-radius: 10px;
  margin: 10px 0px;

  h5 {
    font-weight: 700;
    font-size: 16px;
  }

  h5,
  p {
    padding-left: 30px;
  }

  p {
    font-size: 12px;
    // font-weight: 500;
  }

  .progress {
    width: 60%;
    padding: 15px 0px;
    border-radius: 5px;
    @include flex-none-center;
    background-color: #eff3be;
    margin-top: 25px;
    // font-weight: 400;

    .tier {
      @include flex-none-center;
      gap: 5px;
      font-size: 12px;
      padding-left: 30px;
    }

    .done {
      color: $mo-success;
      // font-size: 14px;
      font-weight: 500;
    }

    .undone {
      color: $mo-light;
    }
  }
}

.active {
  path {
    stroke: green;
  }
}

.inactive {
  path {
    stroke: red;
  }
}

//ACCOUNT BALANCE
.account-balance-container {
  display: flex;
  // height:400px;
  justify-content: space-between; 
  background-color: $mo-light;
  padding: 20px;
  border-radius: 10px;
  margin: 20px 0px;

  .cards-section {
    width: 50%;
    user-select: none;

    .account {
      background-image: url("../icons/cardstack.png");
      background-repeat: no-repeat;
      // background-position: center;
      height: 200px;
      border-radius: 10px;
      width: 80%;
      margin: auto;
      padding: 20px;
      color: $mo-light;
      background-size: contain;
      p {
        // line-height: 0.1cm;
      }
      .accountNumber {
        font-size: 11px;
        // margin-bottom: 5px;
      }

      h4 {
        margin: 0; 
      }

      .last {
        display: flex;
        align-items: flex-end;
        height: 20%;
        font-size: 14px;  
      }
    }

    .toggle {
      margin: 10px auto;
      width: 50px;
      cursor: pointer;

      img {
        width: 100%;
      }
    }
  }

  .mpos-section {
    width: 33%;
    margin: 10px 0px;

    .mpos-card-section {
      background-color: #a2ad1f;
      background-image: url("../icons//arrow.png");
      @include flex-none-center;
      background-repeat: no-repeat;
      background-size: contain;
      padding: 10px 20px;
      border-radius: 10px;
      width: 90%;

      .texts {
        width: 80%;
        font-size: 12px;
        color: $mo-light;
      }

      .image {
        width: 10%;
      }
    }

    .request-pos {
      margin: 20px 0px;
      width: 90%;
      font-size: 12px;
      font-weight: 100;
      background-image: url("../icons//mail_bg.png");
      background-repeat: no-repeat;
      background-position: right;
      background-size: contain;
    }

    .button {
      button {
        @include buttonStyle($mo-success, $mo-light, none);
        width: 30%;
        font-size: 12px;
        text-align: center;
        padding: 5px 10px;
      }
    }
  }

  .insights-section {
    width: 25%; 

    .content {
      width: 70%;
      margin: auto;
      background-color: #fdfbf7;
      padding: 10px;
      border-radius: 10px;
    }

    .insight {
      h5 {
        font-size: 12px;
        font-weight: 00;
      }
    }

    .latest {
      font-weight: 100;
      font-size: 10px;
      // margin: 0 ;
    }
  }
}

//TRANSACTION HISTORY

.transaction-history-container {
  box-sizing: border-box;
  .table-section {
    overflow: scroll;
    background-color: $mo-light;
    border-radius: 10px;
    padding: 20px;
    height: 450px;

    table {
      width: 100%;

      thead {
        td {
          font-weight: 600;
          // font-size: 12px;
        }
      }

      td {
        border-bottom: 1px solid $mo-grey;
        font-size: 11px;
        font-weight: 400;
        padding: 5px;

        .b {
          font-weight: 500;
        }
        b,
        p {
          font-size: 11px;
          word-wrap: break-word;
          width: 100px;
        }
      }

      .status {
        color: $mo-success;
      }
    }
  }

  .add-container {
    position: absolute;
    display: flex;
    width: 92%;
    justify-content: flex-end;
  }

  .add-new {
    width: 70px;
    position: relative;
    // right: 50px;
    // right: 0;
    // left: 100%;
    // left:120px;
    // top: 160%;
    top: -80px;

    img {
      width: 100%;
    }
  }

  .showing {
    color: $mo-grey;

    .shown {
      color: $mo-info;
    }

    font-size: 14px;
    padding: 10px 20px;
  }
}

//FOOTER

.footer-container {
  display: flex;
  justify-content: space-between;
  font-size: 10px;
  padding: 20px;
  // font-weight: 500;
}

// PAYMENTS NAVIGATION
.payments-navigation-container {
  // @include flex-between-start;
  @include flex-evenly-center;
  padding: 10px 25px;
  background-color: $mo-light;
  border-radius: 10px;
  margin: 20px 0px;

  .bill {
    width: 80px;
    padding: 10px 0px;
    border-radius: 10px;
    text-align: center;
    user-select: none;
    // background-color: $mo-info;

    .icon {
      width: 50%;
      height: 60px;
      margin: auto;

      svg {
        width: 100%;
        height: 100%;
        rect {
          stroke: $mo-grey;
          strokeWidth: 2;
        }
      }
    }

    font-size: 10px;
    font-weight: 500;

    &:hover {
      color: white;
      background-color: $mo-info;
      // padding: 10px;

      .icon {
        svg {
          path {
            stroke: $mo-light;
          }
          rect {
            stroke: white;
          }
        }
      }

      font-size: 10px;
      font-weight: 500;
    }
  }
}

.send-money {
  svg {
    stroke: red;

    path {
      stroke: black;
    }
  }
}

.active-bil {
  // width: 70px;
  padding: 10px 5px;
  border-radius: 10px;
  text-align: center;
  color: white;
  background-color: $mo-info;

  .icon {
    width: 50%;
    height: 60px;
    margin: auto;

    svg {
      width: 100%;

      path {
        stroke: $mo-light;
      }
    }
  }

  font-size: 10px;
  font-weight: 500;
}
// }

// ACTIVITIES
.activities-container {
  @extend .transaction-history-container;

  .user {
    @include flex-none-start;
    gap: 10px;

    .image {
      width: 40px;
      height: 40px;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        border-radius: 50%;
      }
    }
  }

  .amount {
    color: $mo-success;
  }

  .status {
    background-color: rgba($color: $mo-success, $alpha: 0.2);
    padding: 5px 10px 5px 3px;
    border-radius: 5px;
    @include flex-none-center;
    gap: 10px;
    width: 125px;
    // font-size: 10px;
    // text-align: left;

    .text {
      width: 60%;
    }
  }

  .options {
    width: 50%;
    // font-size: 17px;
    @include flex-none-center;
  }

  .done {
    width: 20%;
    height: 20px;
    // border: 2px solid red;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

.activities-nav-container {
  nav {
    @include flex-between-center;
    background-color: $mo-light;
    border-radius: 10px;
    margin: 20px 0px;
    padding: 7px;
  }

  .select-search-section {
    width: 60%;
    @include flex-evenly-center;

    select {
      border: none;
      outline: none;
      padding: 5px;
      box-shadow: 0px 0px 10px 1px #f1f1f1;
    }

    input {
      border: none;
      outline: none;
      width: 90%;
    }

    .search-section {
      width: 60%;
    }

    .input {
      background-color: white;
      padding: 10px;
      gap: 10px;
      @include flex-none-center;
      box-shadow: 0px 0px 10px 1px #f1f1f1;
    }
  }

  .buttons {
    width: 40%;
    @include flex-evenly-center;

    .export {
      width: 100px;

      img {
        width: 100%;
      }
    }

    .filter,
    .refresh {
      width: 60px;

      img {
        width: 100%;
      }
    }
  }

  .send-payment-btn {
    width: 100%;
    @include flex-end-center;
    button{
      border:none;
      background-color: #FFAB01;
      font-weight: 400;
      color: white;
      padding: 10px;
      font-size: 12px;
      border-radius: 7px;
      width: 150px;
    }

    .image {
      width: 150px;
      img {
        width: 100%;
        cursor: pointer;
      }
    }
  }
}

.pin-container {
  p {
    line-height: 0.2cm;
    padding: 0 !important;
    font-size: 11px;
    font-weight: 600;
  }
}


@media(max-width:980px){
  .finish-prompt {
    h5,
    p {
      padding: 0;
    }
    .progress {
      width: 100%;
      // padding: 20px 0px;
      display: block;
      height: auto;
    }
  }

  .account-balance-container {
    // flex-direction: column;
    // padding: 10px;

    .cards-section {
      // width: 97%;
      // margin: auto; 

      .account {
        width: 90%; 
        font-size: 11px;
        height: 170px; 
h4{ 
  font-size: 13px;
}
        .last {
          // height: 15%;
          font-size: 10px;
        }
      }
    }


    .mpos-section{ 
      button{ 
        font-size: 12px;
        width: 70% !important;
      }
    
    }
    .mpos-section,
    .insights-section {
      // width: 97%;
    
      .content {
        // width: 100% !important;
        font-size: 12px;
      }
    }
  }
}



@media (max-width: 700px) {
  .dashboard-container {
    // .sidebar {
    //   width: 80%;
    //   z-index: 1;
    // }
    // .hide-sidebar {
    //   width: 0 !important;
    //   z-index: 0 !important;
    // }
    .dashboard-body {
      left: 0 !important;
      width: 100% !important;
      padding: 0;
    }
  }

  .finish-prompt {
    h5,
    p {
      padding: 0;
    }
    .progress {
      width: 100%;
      // padding: 20px 0px;
      display: block;
      height: auto;
    }
  }

  .table-section {
    // overflow-x: scroll ;
    td {
      font-size: 7px !important;
      padding: 5px !important;
    }
  }

  .account-balance-container {
    flex-direction: column;
    padding: 10px;

    .cards-section {
      width: 97%;
      margin: auto;

      .account {
        width: 100%;
        font-size: 14px;
        // height: 150px;
        background-size: contain;

        .last {
          height: 15%;
          font-size: 11px;
        }
      }
    }

    .mpos-section,
    .insights-section {
      width: 97%;

      .content {
        width: 100% !important;
      }
    }
  }

  .footer-container {
    flex-direction: column-reverse;
    padding: 0;
    gap: 10px;
  }


}
