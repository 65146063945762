@import "_mixins";
@import "_variables";

.notification-modal-container {
  overflow: hidden;
  width: 25%; 
  padding: 10px;
  box-shadow: 0px 0px 10px 1px grey;
  font-size: 12px;
  border-radius: 4px;
  position: absolute;
  right: 15%;
  background-color: white; 
  overflow-y: scroll;
  height: 70vh;
  nav {
      @include flex-none-center;
      margin: 10px 0px;
      background-color: rgba(255, 171, 1, 0.1);
      border-radius: 4px;
      padding: 5px; 
      button {
          width: 33%;
      border: none;
      outline: none;
      background-color: transparent;
    }
    gap: 10px;
  }

  .empty-body {
    height: 200px;
    margin: 30px 0px;
  }

.body{
    padding: 10px
}
  .notification{
    @include flex-none-center;
    gap: 10px;
    width: 100%;  
    margin: 10px 0px; 
    cursor: pointer;
    .detail{ 
      width: 70%;
    }
.message{ 
  width: 100%;
  height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; 
}
    .view{ 
      // width: 20%;
        button{
            border: none;
            outline: none;
            background-color: rgba(255, 171, 1, 0.1);
            padding: 5px;
            border-radius: 4px;
            color: #FFAB01;
            font-weight: 600; 

        }
    }

    .viewed{
      @extend .view;
     button{
      background-color: rgba(128, 128, 128, 0.1);
      color: black;
     }
    }
  }

  .unread{
    background-color: #FFFBF2;
  }

  .read{
    background-color: white;
  }
  footer{
    color: $mo-blue; 
    background-color: white;
    padding: 0px 50px;
    position: fixed;
    top: 73vh;
    left: 67%; 
    box-shadow: 0px 0px 10px 0px rgb(201, 200, 200);
  }
}


.notification-container{
    width: 90%; 
    font-size: 12px;

    nav{
        @include flex-evenly-center; 
        margin: 10px 0px;
        background-color: rgba(255, 171, 1, 0.1);
        border-radius: 4px;
        padding: 5px; 
        button { 
        border: none;
        outline: none;
        background-color: transparent;
        padding: 5px;
            border-radius: 5px; 
            width: 100px; 
      }
      gap: 10px;
     
    }

    .body{
        padding: 10px
    }
      .notification{
        @include flex-none-center;
        gap: 10px;
        width: 90%;
        margin: 10px auto;  
        border-radius: 5px;
        padding: 5px;
        width: 100%;
        @include flex-between-center; 
        cursor: pointer;
    
        .view{
          @include flex-none-center;
          gap: 10px;
            button{
                border: none;
                outline: none;
                background-color: rgba(255, 171, 1, 0.1);
                padding: 5px;
                border-radius: 4px;
                color: #FFAB01;
                font-weight: 600;
            }
        }

        .viewed{
          @extend .view;
          button{ 
              background-color: rgba(128, 128, 128, 0.2); 
              color: black; 
          }
      }
      }
    .message{ 
      word-wrap: break-word;
    }

      .unread{
        background-color: #FFFBF2;
      }

      .read{
        background-color: white;
      }
    .notification{ 
        .view{
            // width: 10%;
        }

        .detail{
            width: 95%;
        }
        .icon{
            width: 5%;
        }
    }

    .nav-btn {
      border: none;
      outline: none;
      padding: 5px;
      font-size: 12px;
      border-radius: 10px;
      width: 150px;
      background-color: transparent;
    }

    .active-nav-btn {
      @extend .nav-btn;
      background-color: white;
      color: $mo-info;
      font-weight: 700;
    }
}







@media(max-width:"400px"){
  .notification-modal-container{
    width: 90%;
    margin: auto;
    right: 0;
    left: 0;


    footer{ 
      left: 40%;
    }
  }

  .notification-container{
    .notification{
      padding: 10px 20px;   
      // width: 100%;
      .detail{
        width: 70%;
        margin: auto;  
        
        
        .message{
          font-size: 10px;
          padding: 10px;
          // width: 100%;
          // height: 48px;
          // text-overflow: ellipsis;
          // word-wrap: break-word;
          // white-space:nowrap;
          // overflow: hidden;
        }
      }

      .view{
        // display: none;
      }
    }
  }


}