@import "_variables";
@import "_mixins";


.list-body {
  border-radius: 10px;
//   margin: 20px 0px;
  padding: 20px 10px;
  height: 100%;
  background-color: $mo-light;
  nav {
    @include flex-evenly-center;
    width: 45%;
    background-color: rgba($color: #ffab01, $alpha: 0.1);
    padding: 5px;
    border-radius: 10px;

    .nav-btn {
      border: none;
      outline: none;
      padding: 5px;
      font-size: 12px;
      border-radius: 10px;
      width: 150px;
      background-color: transparent;
    }

    .active-nav-btn {
      @extend .nav-btn;
      background-color: white;
      color: $mo-info;
      font-weight: 700;
    }
  }
}

.pos-body {
  width: 90%;
  margin: 20px auto;
}

.activate-btn {
  button {
    @include buttonStyle(transparent, $mo-info, 1px solid $mo-info);
    width: 20%;
    font-size: 11px;
  }
}

.list-row {
  @include flex-between-center;
//   flex-wrap: wrap;
}
.pos {
  border: 1px solid $mo-grey;
  padding: 10px;
  width: 32%;
  border-radius: 10px;
  height: 270px;
  cursor: pointer;
  b {
    // color: $mo-info;
  }
  p {
    color: grey;
    font-size: 11px;
    width: 80%;
  }

  .image {
    width: 25%;
    height: 50%;
    margin: auto;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
  .button {
    width: 60%;
    margin: auto;

    button {
      @include buttonStyle(transparent, #a2ad1f, 1px solid #a2ad1f);
    }
  }
}

.active-pos {
  @extend .pos; 
  border: 1px solid $mo-info;
  b {
    color: $mo-info;
  }

  .button {
    button {
      @include buttonStyle(transparent, $mo-info, 1px solid $mo-info);
    }
  }
}



//TERMS
.terms-body{
  background-color: #F7F9FA;
  padding: 10px;

  p{
    font-size: 12px;
    text-align: justify;
  }
}

.terms-modal-header{ 
  width: 57%; 
  display: flex;
  justify-content: flex-start;
  margin: auto;
  padding-top: 25px;
}
.footer{
  border: none;
}
.footer-area{
  font-size: 12px;
  // display: flex;
  text-align: left;
  width: 68%;
  margin: auto;

  .checkbox{
    margin: 10px 0px;
    @include flex-none-center;
    gap: 5px;
  }

  .button{
    display: flex;
    justify-content: flex-end;
    margin-top: 30px;
    button{
      @include buttonStyle($mo-info, $mo-light, none);
      width: 30%;

    }
  }
}


.modal-footer-area{
  // background-color: red;
  // display: flex;
  // flex-direction: column;
  // width: 75%;
  // margin: auto;
  font-size: 12px;
  .check{
    // display: flex;
    // justify-content: flex-start;
  }

  .button{
    // display: flex;
    // justify-content: flex-end;
  }
}



//REQUEST POS
.request-pos-container{ 
  input, select{
      padding: 10px;
  }
  .phone-area {
      display: flex;
  
      select {
          // border: 1px solid $mo-grey;
          border-right: none;
          // outline: none; 
          // border: 2px solid red;
          width: 25%;
          padding: 0;
      }
  
      input {
          border-left: none; 
          // border: 2px solid orange;
      }
  
  
  }
}




@media (max-width:"400px"){
  .list-body{
    nav{
      width: 100%;
     .active-nav-btn, .nav-btn{
      font-size: 10px;
      font-weight: 500;
     }
    }


  .activate-btn{
    button{
      width: 50% ;
      padding: 7px;
    }
  }
 
  }
  .list-row{
    flex-direction: column;
    gap: 10px;
    .pos{
      width: 100%;

    }
  }
}