//DASHBOARD NAVIGATION

@import "_variables";
@import "_mixins";

.navigation-container {
  position: fixed;
  width: 100%;
  z-index: 2;
  height: 70px;
}
.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $mo-light;
  height: 100%;
  overflow: hidden;

  .logo-search-section {
    width: 60%;
    @include flex-start-center;
    .logo-section {
      width: 30%;
      // @include flex-evenly-center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      // align-items: center;
      padding: 10px;
      .logo {
        width: 80%;
        img {
          width: 100%;
        }
      }

      .mobile-menu{
        width: 20%;

        img{
          width: 100%;
        }
        display: none;
      }
    }

    .search-section {
      width: 60%;
      @include flex-start-center;
      border-radius: 8px;
      padding: 10px;

      .image {
        width: 8%;
        @include flex-center-center;
        img {
          width: 100%;
        }
      }
      .input {
        width: 60%;
        @include flex-center-center;
        gap: 10px;
        background-color: $mo-grey-light;
        border-radius: 20px;
        padding: 0px 20px;

        input {
          width: 100%;
          border: none;
          outline: none;
          padding: 10px;
          border-radius: 20px;
          background-color: $mo-grey-light;
        }
      }
    }
  }

  .icons-profile-section {
    width: 40%;
    @include flex-none-center;

    .icons-section {
      width: 50%;
      @include flex-evenly-center;
      padding-bottom: 10px;

      .content {
        width: 40px;
        border-radius: 100%;
        .counter {
          position: relative;
          border-radius: 50%;
          width: 25px;
          height: 25px;
          left: 60%;
          top: 20px;
          font-size: 12px;
          color: $mo-light;
          font-weight: 500;
          @include flex-center-center;
        }
        .bell {
          background-color: $mo-blue;
        }
        .mail {
          background-color: $mo-success;
        }
        .comment {
          background-color: $mo-info;
        }

        .icon {
          img {
            width: 100%;
          }
        }
      }
    }

    .profile-section {
      @include flex-center-center;  
      display: flex;
      width: 50%;
      gap: 10px;

      .user-rank {
        background-color: $mo-danger;
        color: $mo-light;
        padding: 10px;
        border-radius: 10px;
        font-size: 13px;
        font-weight: 400;  
        user-select: none;

        // display: flex;
        // align-items: center;
      }

      .name {
        line-height: 0px;
        user-select: none;  
        // width: 80%;  
        p {
          font-weight: 100;
        }
 
      }
      .more{ 
        height: 50px;
        // width: 20%;
        // background-color: red;
        // display: flex;
        // align-items: flex-start !important;
        // height: 100% !important;
     }
    }
  }
}

.dropdown { 
  width: 170px;
  position: absolute;
  right: 5%;
  z-index: 2;
  border-radius: 4px;
  background-color: white;
  box-shadow: 0px 0px 1px 1px #f1f1f1;
  padding: 6px;

  .item{
    cursor: pointer;
    margin: 15px 0px;
    padding: 5px;
    .icon{
        width: 20px;
        img{
            width: 100%;
        }
    }
    @include flex-none-center;
    gap: 10px;
    font-size: 13px;
    
  }
}

// @media (max-width: 700px) {
//   .navigation-container {
//     // display: none;
//   }
// }


@media(max-width:900px){
  .navigation{
    // padding-top: 10px;
    .logo-search-section{
      width: 25%;
  
      .logo-section{
        width: 100%;
        .logo{
          width: 90%;
        }
        .mobile-menu{ 
          display: block;
          width: 10%;
        }
      }
      .search-section{
        display: none;
      }
    }
  
    .icons-profile-section{ 
      width: 65%;
      // justify-content: space-between; 
      gap: 10px;
      // padding: 10px;
  
      .icons-section{
        width: 30%;
        gap: 10px;
  
        // .content{
        //   // width: 60% !important;
        //   .counter{
        //     width: 12px;
        //     height: 12px;
        //     top: 10px;
        //     left: 65%;
        //     font-size: 7px; 
        //   }
        // } 
      }
  
  
      .profile-section{
        padding: 5px;
        width: 70%;
     
        .name{ 
          // width: 90%;
         p{
          font-size: 10px;  
         }

          h5{ 
            font-size: 14px;
            word-wrap: break-word;
          }
        }
        .more{
          // width: 10%;
        }
       
      }
    }
  }
  }




@media(max-width:400px){
.navigation{
  .logo-search-section{
    width: 35%;

    .logo-section{
      width: 100%;
      .logo{
        width: 90%;
      }
      .mobile-menu{ 
        display: block;
        width: 10%;
      }
    }
    .search-section{
      display: none;
    }
  }

  .icons-profile-section{ 
    width: 65%;
    // justify-content: space-between; 
    gap: 10px;
    // padding: 10px;

    .icons-section{
      width: 30%;
      gap: 10px;

      .content{
        width: 60% !important;
        .counter{
          width: 12px;
          height: 12px;
          top: 10px;
          left: 65%;
          font-size: 7px; 
        }
      } 
    }


    .profile-section{
      padding: 5px;
      width: 70%;
      .user-rank{
        // width: 20%;
        // font-size: 11px;
        // padding: 0px;
        // height: 30px;
        // width: 20%;
        // justify-content: center;
      }
      .name{ 
        // width: 90%;
        font-size: 10px;
      }
      .more{
        // width: 10%;
      }
      .name h5{
        font-size: 11px;
        word-wrap: break-word;
      }
    }
  }
}
}