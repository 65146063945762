@import "_variables";
@import "_mixins";

.bills-list-container{
    .list-body{
        display: flex; 
        // @include flex-evenly-center;
        flex-wrap: wrap;
        gap: 20px;
        // column-gap: 20px;
        // row-gap: 20px;
        padding: 20px;


        .item{
            background-color: #F5F5F5;
            flex-basis: 200px;
            // height: 200px;
            border-radius: 7px;
            padding: 20px; 

            .icon{
                width: 50px;
                height: 50px; 
                margin-bottom:10px;
                svg{
                    width: 100%;
                    height: 100%;
                }

            } 

            .title{
                font-weight: 700; 
                font-size: 14px;

            }

            .description{
                font-size: 12px;

            }

            &:hover{
                background-color: #EFF3BE;
                .icon{
                    width: 50px;
                    height: 50px; 
                    margin-bottom:10px;
                    svg{
                        width: 100%;
                        height: 100%;
                        path {
                            stroke: #FFBB33; 
                        } 
                          rect {
                            stroke: #FFBB33;
                          }
                    }
    
                } 
            }
            
        }
    }
}