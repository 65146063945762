@import "_variables";
@import "_mixins";

.section-info {
  text-align: center;
  font-size: 12px;
  background-color: #eff3be;
  padding: 5px 10px;
  border-radius: 5px;
  font-weight: 500;
  color: #474747;
  margin: 25px 0px;
}
.modalContent {
  padding-bottom: 50px;
}

.method-area {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid $mo-grey;
  padding: 7px;
  margin: 10px 0px;
  border-radius: 10px;
  // padding: 10px;

  .method,
  .charge-proceed {
    display: flex;
    align-items: center;
  }

  .method {
    gap: 10px;
    width: 60%;
    font-size: 12px;
    font-weight: 600;
    .icon {
      width: 15%;
      img {
        width: 100%;
      }
    }

    .text {
      width: 80%;

      .currency {
        font-size: 11px;
        color: $mo-grey;
        font-weight: 400;
      }
    }
  }
  .amount {
    width: 50%;
    font-size: 12px;
    text-align: right;
    b {
      font-weight: 700;
    }
  }
  .charge-proceed {
    justify-content: space-evenly;
    width: 20%;
    gap: 10px;

    .charge {
      width: 80%;
      text-align: center;
      color: $mo-success;
      background-color: #ecf9ea;
      border-radius: 4px;
      padding: 4px;
      font-weight: 700;
      font-size: 11px;
    }

    .proceed {
      width: 15%;
      @include flex-none-center;
      img {
        width: 100%;
      }
    }
  }
}

.added-cards {
  p {
    padding: 0;
    margin: 0;
  }
  .each-card {
    user-select: none;
    border: solid 1px #D1D1D1;
     @include flex-between-center;
    width: 80%;
    padding: 5px;
    border-radius: 1px;
    margin: 20px auto;
    p:nth-child(1){
        font-weight: 700;
    }
    .bank-logo,
    .card-type {
      width: 30px;
      img {
        width: 100%;
      }
    }

    .card-details {
      font-size: 10px;
    }
  }


  .add-card{
    @extend .each-card;  
    justify-content: flex-start;
    gap: 20px;
    border: 1px solid $mo-info;
    border-radius: 7px;
    color: $mo-info;
    padding: 10px;
    font-size: 18px;

    .plus{ 
       width: 20px;
       img{
        width: 100%;
       }
    }
  }
}
