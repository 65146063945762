$primary-font: Museo Sans Rounded;

$primaryColor: #FFBB33;
$mo-info: #FFBB33;
$mo-light: #fff;
$mo-success: #32D16D; 
$mo-danger: #F42F4B;
$mo-grey:#D1D1D1;
$mo-grey-light:#FAFAFA;
$mo-bg-grey:#F9F9F9;
$mo-blue:#216FED;

