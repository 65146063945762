@import "_variables";
@import "_mixins";

.tiers-container {
  p {
    padding: 0;
    margin: 0;
  }

  .tiers-section {
    @include flex-between-center;
    flex-wrap: wrap;
    margin: 20px 0px;
    gap: 10px;
  }
  .kyc-status {
    @include flex-end-center;
    font-weight: 600; 
    height: 5%;
   
  }

  .kyc-success{
    color: $mo-success;
    span{
        background-color: #4DC73633;
        width: 100px;
        text-align: center;
        border-radius: 10px;
        padding: 2px;
    }
  } 
  .kyc-review{
    @extend .kyc-success;
    color:$mo-info;
    span{
        background-color: #EFF3BE; 
    }
   
  }
  .tier {
    background-color: $mo-light;
    flex-basis: 300px;
    height: 350px;
    padding: 15px;
    border-radius: 7px;
    font-size: 12px; 
    

    .level { 
      font-weight: 800;
      height: 5%;
      margin: 0px 0px 20px 0px;
    }

    .icon {
      width: 70px;
      height: 70px;
      svg {
        width: 100%;
        height: 100%;
      }
    } 
 
    .details {
        height: 50%;
        user-select: none;
      .item {
        margin: 10px 0px;
        @include flex-between-center;

        .status {
          color: $mo-blue;
          font-weight: 600;
        }

        .limit {
          color: #A2AD1F;
          font-weight: 600;
          font-size: 12px;
        }
        .pending{
            color: #ADB7BE
        }
      }
    }
    .to-do {
      @include flex-end-center;
      font-weight: 600;
      gap: 5px;
      margin-top: 15px;
      color: $mo-info;
    }

    &:hover{
        border: 1px solid $mo-info;
        .level {
            color: $mo-info; 
          }
          svg { 
            circle {
              fill: $mo-info;
            }
            path {
              fill: $mo-light;
            }
          }
           .to-do{ 
            svg { 
                path {
                  fill: $mo-info;
                }
              }
           }
    }
  }

  .inactive-kyc {
    background-color: rgba(128, 128, 128, 0.1);
    flex-basis: 300px;
    height: 350px;
    padding: 15px;
    border-radius: 7px;
    font-size: 12px; 
    

    .level { 
      font-weight: 800;
      height: 5%;
      margin: 0px 0px 20px 0px;
    }

    .icon {
      width: 70px;
      height: 70px;
      svg {
        width: 100%;
        height: 100%;
      }
    } 
 
    .details {
        height: 50%;
        user-select: none;
      .item {
        margin: 10px 0px;
        @include flex-between-center;

        .status {
          color: $mo-blue;
          font-weight: 600;
        }

        .limit {
          color: #A2AD1F;
          font-weight: 600;
          font-size: 12px;
        }
        .pending{
            color: #ADB7BE
        }
      }
    }
    .to-do {
      @include flex-end-center;
      font-weight: 600;
      gap: 5px;
      margin-top: 15px;
      color: $mo-info;
    }

    &:hover{
        border: none;
        .level {
            // color: $mo-grey; 
          }
          svg { 
            circle {
              // fill: $mo-grey;
            } 
            path {
              // fill: $mo-light;
            }
          }
           .to-do{ 
            svg { 
                path {
                  // fill: $mo-grey;
                }
              }
           }
    }
  }


}
