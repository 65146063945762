@import "_variables";
@import "_mixins";

.transaction-fee {
  background-color: #eff3be;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  padding: 5px;
  font-weight: 500;
}

.airtime-container {
  .network-providers {
    @include flex-center-center;

    gap: 10px;
    margin: 15px 0px;
    .provider {
      width: 60px;
      height: 60px;
      border-radius: 10px;
      border: 1.5px solid white;
      padding: 2px;
      cursor: pointer;
      box-shadow: 0px 0px 5px 0px rgb(227, 227, 227);
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 10px;
      }
    }

    .active-provider {
      @extend .provider;
      border: 1.5px solid $mo-info;
    }
  }

  label {
    user-select: none !important;
  }

  .checkbox-area {
    input {
      width: 10%;
      margin: 0px 0px 0px -10px;
    }
    label {
      width: 80%;
    }
  }

  .select-contact {
    border: 1px solid $mo-grey;
    @include flex-center-center;
    padding: 10px;
  }
}

//PAYMENT METHOD
.payment-method-container {
  .bill-summary {
    .item {
      @include flex-between-center;
      margin: 10px 0px;
      p,
      b {
        font-size: 13px;
      }
    }
  }

  .method {
    border: 1px solid $mo-grey;
    border-radius: 7px;
    margin: 20px 0px;
    padding: 0px 10px;

    .heading {
      font-size: 9px;
      color: $mo-grey;
      padding: 0;
      margin: 0;
    }
    .content {
      padding: 5px;
      @include flex-none-center;
      font-size: 12px;
      gap: 10px;
      width: 100%;

      .icon {
        width: 20px;
        img {
          width: 100%;
        }
      }

      .details-status-section {
        @include flex-between-center;
        width: 90%;

        .details {
          width: 90%;
        }
      }
    }
  }

  .active-method {
    @extend .method;
    background-color: #fdfbf7;
  }
}

.vertical-center {
  display: flex;
  gap: 10px;
  align-items: center;
}

// CABLE TV

.cableTv-container {
  @extend .airtime-container;

  .network-providers {
    gap: 5px;
  }
  .provider {
    width: 25%;
    height: 70px;
    cursor: pointer;
    box-shadow: 0px 0px 5px 0px rgb(227, 227, 227);
    overflow: hidden;
    // border-radius: 7px;
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      transition: all 0.1s linear;

      // &:hover{
      //   transform: scale(2.1);
      // }
    }
  }
  .active-provider {
    width: 25%;
    height: 70px;
    cursor: pointer;
    box-shadow: 0px 0px 5px 0px rgb(227, 227, 227);
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    border: 1.5px solid $mo-info;
  }

  .cableTv-amount {
    background-color: rgba(255, 171, 1, 0.1);
  }
}

//AIRTIME/DATA SUCCESS
.success-container {
  width: 90%;
  font-size: 12px;

  .banner-section {
    background-color: #f5f5f5;
    height: 50px;
    padding: 10px 20px;
    border-radius: 7px;
    @include flex-between-center;
    .amount {
      font-weight: 600;
    }

    .status {
      background-color: #3bb65f;
      padding: 5px;
      width: 80px;
      text-align: center;
      border-radius: 7px;
      color: white;
      font-size: 12px;
      font-weight: 500;
    }
  }

  .item {
    @include flex-between-center;
    margin: 20px 0px;
  }

  .reference {
    font-weight: 600;
  }

  .sender-profile {
    @include flex-none-center;
    gap: 5px;
    .details {
      width: 60%;
      line-break: anywhere;
      text-align: left;
    }

    .icon {
      width: 50px;
      height: 50px;
      border-radius: 100%;
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
      }
    }
  }

  .debited-account {
    width: 40%;
    text-align: right;
  }

  .download-btn {
    @include buttonStyle($mo-light, $mo-info, 1.5px solid $mo-info);
    width: 200px;
    font-weight: 400;
  }

  .processed-by-btn{
    width: 200px;
    img{
      width: 100%;
    }
  }

  .powered-by-mongoro{
    font-weight: 300;
    font-size: 12px;
  }
}


//CSS FOR INLINE TEMPLATING
// .success-container {
//   width: 90%;
//   font-size: 12px;
// }

// .success-container .banner-section {
//   background-color: #f5f5f5;
//   height: 50px;
//   padding: 10px 20px;
//   border-radius: 7px;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }

// .success-container .banner-section .amount {
//   font-weight: 600;
// }

// .success-container .banner-section .status {
//   background-color: #3bb65f;
//   padding: 5px;
//   width: 80px;
//   text-align: center;
//   border-radius: 7px;
//   color: white;
//   font-size: 12px;
//   font-weight: 500;
// }

// .success-container .item {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   margin: 20px 0px;
// }

// .success-container .reference {
//   font-weight: 600;
// }

// .success-container .sender-profile {
//   display: flex;
//   align-items: center;
//   gap: 5px;
// }

// .success-container .sender-profile .details {
//   width: 60%;
//   line-break: anywhere;
//   text-align: left;
// }

// .success-container .sender-profile .icon {
//   width: 50px;
//   height: 50px;
//   border-radius: 100%;
//   overflow: hidden;
// }

// .success-container .sender-profile .icon img {
//   width: 100%;
//   height: 100%;
//   object-fit: cover;
//   object-position: top;
// }

// .success-container .debited-account {
//   width: 40%;
//   text-align: right;
// }

// .success-container .download-btn {
//   width: 200px;
//   padding: 10px;
//   border: 1.5px solid #ffbb33;
//   outline: none;
//   border-radius: 3px;
//   background-color: #fff;
//   color: #ffbb33;
//   font-weight: 400;
// }

// .success-container .processed-by-btn {
//   width: 200px;
// }

// .success-container .processed-by-btn img {
//   width: 100%;
// }

// .success-container .powered-by-mongoro {
//   font-weight: 300;
//   font-size: 12px;
// }
